import { render, staticRenderFns } from "./video-card.vue?vue&type=template&id=9bb8e770&scoped=true&lang=pug"
import script from "./video-card.vue?vue&type=script&lang=coffee"
export * from "./video-card.vue?vue&type=script&lang=coffee"
import style0 from "./video-card.vue?vue&type=style&index=0&id=9bb8e770&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9bb8e770",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CraftVisual: require('/opt/build/repo/site/components/globals/craft-visual.coffee').default,ButtonVideoLauncher: require('/opt/build/repo/site/components/globals/button-video-launcher.vue').default,BtnCraft: require('/opt/build/repo/site/components/globals/btn/craft.vue').default})
